import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import PageTitle from '~/components/PageTitle';
import ResumeSkill from '~/components/ResumeParticle/ResumeSkill';
import ResumeContainer from '~/components/ResumeParticle/ResumeContainer';
import ResumeParticle from '~/components/ResumeParticle';
import images from '~/assets/images';
import styles from './Resume.module.scss';

const cx = classNames.bind(styles);

function Resume({ educations, experiences, skills }) {
    return (
        <div className={cx('wrapper')}>
            <PageTitle title="Resume" />
            <div className={cx('container')}>
                <ResumeParticle title="Education">
                    {educations.map((education, index) => (
                        <ResumeContainer
                            key={index}
                            imgSrc={images[education.logo]}
                            imgAlt={education.title}
                            time={education.time}
                            name={education.title}
                            info={
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: education.content,
                                    }}
                                />
                            }
                        />
                    ))}
                </ResumeParticle>
                <ResumeParticle title="Experience">
                    {experiences.map((experience, index) => (
                        <ResumeContainer
                            key={index}
                            imgSrc={images[experience.logo]}
                            imgAlt={experience.title}
                            time={experience.time}
                            name={experience.title}
                            info={
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: experience.content,
                                    }}
                                />
                            }
                        />
                    ))}
                </ResumeParticle>
                <ResumeParticle title="Skills" endLine={false}>
                    <>
                        {skills.map((skill, index) => (
                            <ResumeSkill
                                key={index}
                                imgSrc={images[skill.src]}
                                imgAlt={skill.name}
                                time={skill.time}
                                name={skill.name}
                                percent={skill.percent}
                            />
                        ))}
                    </>
                </ResumeParticle>
            </div>
        </div>
    );
}

Resume.propTypes = {
    educations: PropTypes.array,
    experiences: PropTypes.array,
    skills: PropTypes.array,
};

export default Resume;
