import { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { TypeAnimation } from 'react-type-animation';

import styles from './HomePage.module.scss';

const cx = classNames.bind(styles);

const jobs = ['Web Developer', 'Game Designer', 'Game Developer', 'Tester'];

function Home() {
    const [session, setSession] = useState('Good morning!');

    const sequence = jobs.flatMap((job, index) => [job, 2000]).filter(Boolean);

    useEffect(() => {
        const today = new Date();
        const curHour = today.getHours();

        if (curHour < 12) {
            setSession('Good morning!');
        } else if (curHour < 18) {
            setSession('Good afternoon!');
        } else {
            setSession('Good evening!');
        }
    }, []);

    return (
        <div className={cx('wrapper')}>
            <div className={cx('hello')}>
                {session}
                <span> I am</span>
            </div>
            <div className={cx('name')}>NGUYEN BINH MINH</div>
            <div className={cx('jobs')}>
                I’m a <TypeAnimation sequence={sequence} speed={50} repeat={Infinity} cursor={false} />
            </div>
        </div>
    );
}

export default Home;
