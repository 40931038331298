const routes = {
    home: '/',
    about: '/about',
    resume: '/resume',
    services: '/services',
    projects: '/projects',
    blogs: '/blogs',
    contact: '/contact',
};

export default routes;
