import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './ResumeContainer.module.scss';

const cx = classNames.bind(styles);

function ResumeContainer({ imgSrc, imgAlt, time, name, info }) {
    return (
        <div className={cx('container')}>
            <img className={cx('logo')} src={imgSrc} alt={imgAlt} />
            <div className={cx('content')}>
                <h5 className={cx('time')}>{time}</h5>
                <h5 className={cx('name')}>{name}</h5>
                <div className={cx('info')}>{info}</div>
            </div>
        </div>
    );
}

ResumeContainer.propsTypes = {
    imgSrc: PropTypes.node.isRequired,
    imgAlt: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    info: PropTypes.node.isRequired,
};

export default ResumeContainer;
