import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './ResumeParticle.module.scss';

const cx = classNames.bind(styles);

function ResumeParticle({ title, children, endLine = true }) {
    return (
        <div className={cx('wrapper', { 'end-line': endLine })}>
            <h4 className={cx('title')}>{title}</h4>
            {children}
        </div>
    );
}

ResumeParticle.propsTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
};

export default ResumeParticle;
