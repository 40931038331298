import { Slide } from 'react-slideshow-image';
import classNames from 'classnames/bind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';

import { useViewport } from '~/hooks';
import ProjectSlide from './ProjectSlide';
import styles from './ProjectPart.module.scss';

const cx = classNames.bind(styles);

const properties = {
    prevArrow: (
        <button className={cx('slide-btn')}>
            <FontAwesomeIcon icon={faAngleLeft} />
        </button>
    ),
    nextArrow: (
        <button className={cx('slide-btn')}>
            <FontAwesomeIcon icon={faAngleRight} />
        </button>
    ),
};

function ProjectPart({ title, projects }) {
    const viewport = useViewport();
    const isMobile = viewport <= 1024;

    return (
        <div className={cx('wrapper')}>
            <h4 className={cx('title')}>{title}</h4>
            <Slide
                transitionDuration={isMobile ? 0 : 500}
                indicators={true}
                autoplay={isMobile}
                duration={3000}
                {...properties}
            >
                {projects.map((project, index) => (
                    <div key={index} className={cx('each-slide-effect')}>
                        <ProjectSlide
                            name={project.title}
                            buttons={project.buttons}
                            photos={project.photos}
                            content={project.content}
                        />
                    </div>
                ))}
            </Slide>
        </div>
    );
}

export default ProjectPart;
