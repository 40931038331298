import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './ServiceItem.module.scss';

const cx = classNames.bind(styles);

function ServiceItem({ imgSrc, imgAlt, title, content }) {
    return (
        <div className={cx('wrapper')}>
            <img className={cx('logo')} src={imgSrc} alt={imgAlt} />
            <h4 className={cx('title')}>{title}</h4>
            <div className={cx('content')}>{content}</div>
        </div>
    );
}

ServiceItem.propTypes = {
    imgSrc: PropTypes.node.isRequired,
    imgAlt: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
};

export default ServiceItem;
