import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './ResumeSkill.module.scss';

const cx = classNames.bind(styles);

function ResumeContainer({ imgSrc, imgAlt, time, name, percent }) {
    return (
        <div
            className={cx('container')}
            style={{
                '--skill-percent': percent,
            }}
        >
            <img className={cx('logo')} src={imgSrc} alt={imgAlt} />
            <div className={cx('content')}>
                <h5 className={cx('time')}>{time}</h5>
                <div className={cx('experience-bar')}>
                    <h5 className={cx('name')}>
                        <span>{name}</span>
                        <span>{percent}</span>
                    </h5>
                    <div className={cx('experience')}></div>
                </div>
            </div>
        </div>
    );
}

ResumeContainer.propsTypes = {
    imgSrc: PropTypes.node.isRequired,
    imgAlt: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    perent: PropTypes.string.isRequired,
};

export default ResumeContainer;
