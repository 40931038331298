import { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './Input.module.scss';

const cx = classNames.bind(styles);

const Input = forwardRef(({ type, placeholder, rows, cols, className, ...passProps }, ref) => {
    const [value, setValue] = useState('');

    const handleChange = (e) => {
        setValue(e.target.value);
    };

    let Comp = 'input';
    const props = {
        type,
        placeholder,
        ...passProps,
    };

    if (!type) {
        props.rows = rows;
        props.cols = cols;
        Comp = 'textarea';
    }

    const classes = cx('wrapper', {
        [className]: className,
    });

    return (
        <Comp
            ref={ref}
            value={value}
            className={classes}
            onChange={(e) => handleChange(e)}
            {...props}
        />
    );
});

Input.propTypes = {
    type: PropTypes.string,
    placeholder: PropTypes.string,
    rows: PropTypes.string,
    cols: PropTypes.string,
    className: PropTypes.string,
};

export default Input;
