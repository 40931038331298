import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import ProjectPart from '~/components/ProjectPart/ProjectPart';
import PageTitle from '~/components/PageTitle';
import styles from './Projects.module.scss';

const cx = classNames.bind(styles);

function Projects({ projects }) {
    //projects is Project Areas content area_name and list of projects in that area
    return (
        <div className={cx('wrapper')}>
            <PageTitle title="Projects" />
            {projects.map((project, index) => (
                <ProjectPart key={index} title={project.area} projects={project.projects} />
            ))}
        </div>
    );
}

Projects.propTypes = {
    projects: PropTypes.array.isRequired,
};

export default Projects;
