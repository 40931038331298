import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import Contact from '~/pages/Contact';
import Header from '~/layouts/conponents/Header';
import styles from './DefaultLayout.module.scss';

const cx = classNames.bind(styles);

function DefaultLayout({ children, contactInfo }) {
    return (
        <div className={cx('wrapper')}>
            <Header />
            <div className={cx('content')}>{children}</div>
            <Contact contactInfo={contactInfo} />
        </div>
    );
}

DefaultLayout.propTypes = {
    children: PropTypes.node.isRequired,
    contactInfo: PropTypes.array,
};

export default DefaultLayout;
