import { ToastContainer, toast } from 'react-toastify';
import { Fragment, useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { useViewport } from './hooks';
import { proxy } from './assets/api';
import { publicRoutes } from '~/routes';
import MobileLayout from './layouts/MobileLayout';
import DefaultLayout from '~/layouts';
import Loading from './components/Loading';

function App() {
    const [data, setData] = useState({
        educations: null,
        experiences: null,
        skills: null,
        services: null,
        about: null,
        contactInfo: null,
        projects: null,
    });
    const [loadSuccess, setLoadSuccess] = useState(false);
    const viewport = useViewport();
    const isMobile = viewport <= 1024;

    useEffect(() => {
        fetch(proxy + '/api/getData').then(async (res) => {
            if (res.ok) {
                const data = await res.json();
                setData(data);
                setLoadSuccess(true);
            } else {
                const notify = () => toast.error('Failed to get portfolio data');
                notify();
            }
        });
    }, []);

    return (
        <Router>
            {loadSuccess ? (
                <>
                    <div className="App">
                        <Routes>
                            {publicRoutes.map((route, index) => {
                                const Page = route.component;
                                let Layout = isMobile ? MobileLayout : DefaultLayout;

                                if (route.layout) {
                                    Layout = isMobile ? route.mblayout : route.layout;
                                } else if (route.layout === null) {
                                    Layout = Fragment;
                                }

                                return (
                                    <Route
                                        key={index}
                                        path={route.path}
                                        element={
                                            <Layout contactInfo={data.contactInfo}>
                                                <Page {...data} />
                                            </Layout>
                                        }
                                    />
                                );
                            })}
                        </Routes>
                    </div>
                    <ToastContainer />
                </>
            ) : (
                <Loading />
            )}
        </Router>
    );
}

export default App;
