import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import MobileHeader from '../conponents/MobileHeader';
import styles from './MobileHeaderOnly.module.scss';

const cx = classNames.bind(styles);

function MobileHeaderOnly({ children }) {
    return (
        <div className={cx('wrapper')}>
            <div className={cx('content')}>{children}</div>
            <MobileHeader />
        </div>
    );
}

MobileHeaderOnly.propTypes = {
    children: PropTypes.node.isRequired,
};

export default MobileHeaderOnly;
