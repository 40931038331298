import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import PageTitle from '~/components/PageTitle';
import ServiceItem from '~/components/ServiceItem';
import images from '~/assets/images';
import styles from './Services.module.scss';

const cx = classNames.bind(styles);

function Services({ services }) {
    return (
        <div className={cx('wrapper')}>
            <PageTitle title="Services" />
            <div className={cx('items')}>
                {services.map((service, index) => (
                    <ServiceItem
                        key={index}
                        imgSrc={images[service.src]}
                        imgAlt={service.alt}
                        title={service.title}
                        content={service.content}
                    />
                ))}
            </div>
        </div>
    );
}

Services.propTypes = {
    services: PropTypes.array,
};

export default Services;
