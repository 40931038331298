import classNames from 'classnames/bind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faItchIo } from '@fortawesome/free-brands-svg-icons';

import Button from '~/components/Button';
import styles from './ProjectSlide.module.scss';

const cx = classNames.bind(styles);

const icons = {
    faGithub: <FontAwesomeIcon icon={faGithub} />,
    faItchIo: <FontAwesomeIcon icon={faItchIo} />,
};

function ProjectSlide({ name, buttons, content, photos }) {
    return (
        <div className={cx('wrapper')} style={{ backgroundImage: `url(${photos})` }}>
            <div className={cx('container')}>
                <div className={cx('project-info')}>
                    <h3 className={cx('project-name')}>{name}</h3>
                    <div className={cx('project-link')}>
                        {buttons.map((button, index) => (
                            <Button
                                key={index}
                                href={button.href}
                                primary={true}
                                leftIcon={icons[button.icon]}
                            >
                                {button.text}
                            </Button>
                        ))}
                    </div>
                </div>
                <div
                    className={cx('project-descript')}
                    dangerouslySetInnerHTML={{
                        __html: content,
                    }}
                />
            </div>
        </div>
    );
}

export default ProjectSlide;
