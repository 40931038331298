import classNames from 'classnames/bind';

import { useScrollDirection } from '~/hooks';
import MenuItem from '~/components/MenuItem';
import { publicRoutes } from '~/routes';
import styles from './Header.module.scss';

const cx = classNames.bind(styles);

function Headers() {
    const scrollDirection = useScrollDirection();

    return (
        <div className={cx('wrapper', { show: scrollDirection === 'up' })}>
            {publicRoutes.map((route, index) => (
                <MenuItem key={index} path={route.path} name={route.name} />
            ))}
        </div>
    );
}

export default Headers;
