import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import MobileHeader from '../conponents/MobileHeader';
import Contact from '~/pages/Contact';
import styles from './MobileLayout.module.scss';

const cx = classNames.bind(styles);

function MobileLayout({ children, contactInfo }) {
    return (
        <div className={cx('wrapper')}>
            <div className={cx('content')}>{children}</div>
            <Contact className={cx('contact')} contactInfo={contactInfo} />
            <MobileHeader />
        </div>
    );
}

MobileLayout.propTypes = {
    children: PropTypes.node.isRequired,
    contactInfo: PropTypes.array,
};

export default MobileLayout;
