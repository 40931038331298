import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faAddressCard,
    faBriefcase,
    faHome,
    faPenRuler,
    faPhone,
    faUserGear,
} from '@fortawesome/free-solid-svg-icons';
import config from '~/config';

// Layouts
import HeaderOnly from '~/layouts/HeaderOnly';
import MobileHeaderOnly from '~/layouts/MobileHeaderOnly';

// Pages
import Home from '~/pages/Home';
import About from '~/pages/About';
import Resume from '~/pages/Resume';
import Services from '~/pages/Services';
import Projects from '~/pages/Projects';
import Contact from '~/pages/Contact';

// Public routes
const publicRoutes = [
    {
        path: config.routes.home,
        component: Home,
        name: 'Home',
        icon: <FontAwesomeIcon icon={faHome} />,
        layout: HeaderOnly,
        mblayout: MobileHeaderOnly,
    },
    {
        path: config.routes.about,
        component: About,
        name: 'About',
        icon: <FontAwesomeIcon icon={faAddressCard} />,
    },
    {
        path: config.routes.resume,
        component: Resume,
        name: 'Resume',
        icon: <FontAwesomeIcon icon={faBriefcase} />,
    },
    {
        path: config.routes.services,
        component: Services,
        name: 'Services',
        icon: <FontAwesomeIcon icon={faUserGear} />,
    },
    {
        path: config.routes.projects,
        component: Projects,
        name: 'Projects',
        icon: <FontAwesomeIcon icon={faPenRuler} />,
    },
    {
        path: config.routes.contact,
        component: Contact,
        name: 'Contact',
        icon: <FontAwesomeIcon icon={faPhone} />,
        layout: HeaderOnly,
        mblayout: MobileHeaderOnly,
    },
];

// Private routes
const privateRoutes = [];

export { publicRoutes, privateRoutes };
