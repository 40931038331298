import { useRef } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './ContactInfo.module.scss';

const cx = classNames.bind(styles);

function ContactInfo({ icon, mainInfo, bonusInfo }) {
    const infoRef = useRef(null);

    const handleCopy = () => {
        navigator.clipboard.writeText(infoRef.current.innerText);
        const notify = () => toast.success('Copied');
        notify();
    };

    return (
        <div className={cx('wrapper')} onClick={handleCopy}>
            <div className={cx('icon')}>{icon}</div>
            <div className={cx('info')}>
                <h4 ref={infoRef} className={cx('main-info')}>
                    {mainInfo}
                </h4>
                <h4 className={cx('bonus-info')}>{bonusInfo}</h4>
            </div>
        </div>
    );
}

ContactInfo.propTypes = {
    icon: PropTypes.node.isRequired,
    mainInfo: PropTypes.string.isRequired,
    bonusInfo: PropTypes.string,
};

export default ContactInfo;
