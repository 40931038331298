import classNames from 'classnames/bind';

import { useScrollDirection } from '~/hooks';
import MenuItem from '~/components/MenuItem';
import { publicRoutes } from '~/routes';
import styles from './MobileHeader.module.scss';

const cx = classNames.bind(styles);

function MobileHeader() {
    const scrollDirection = useScrollDirection();

    return (
        <div className={cx('wrapper', { show: scrollDirection === 'down' })}>
            {publicRoutes.map((route, index) => (
                <MenuItem key={index} path={route.path} icon={route.icon} />
            ))}
        </div>
    );
}

export default MobileHeader;
