import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames/bind';

import styles from './MenuItem.module.scss';

const cx = classNames.bind(styles);

function MenuItem({ path, name, icon }) {
    const handleClick = () => {
        window.scrollTo(0, 0);
    };

    return (
        <NavLink
            className={(nav) => cx('link', { active: nav.isActive })}
            to={path}
            onClick={handleClick}
        >
            {icon}
            {name}
            <div className={cx('underline')}></div>
        </NavLink>
    );
}

MenuItem.propTypes = {
    path: PropTypes.string.isRequired,
    name: PropTypes.string,
    icon: PropTypes.node,
};

export default MenuItem;
