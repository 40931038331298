import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './PageTitle.module.scss';

const cx = classNames.bind(styles);

function PageTitle({ title }) {
    return (
        <div className={cx('wrapper')}>
            <h4>{title}</h4>
        </div>
    );
}

PageTitle.propTypes = {
    title: PropTypes.string.isRequired,
};

export default PageTitle;
