const images = {
    ctuetLogo: require('~/assets/images/Logo_ctuet.png'),
    nodejsLogo: require('~/assets/images/node-js.png'),
    reactLogo: require('~/assets/images/science.png'),
    gamepadLogo: require('~/assets/images/godot.png'),
    webLogo: require('~/assets/images/web.png'),
    apiLogo: require('~/assets/images/api.png'),
    databaseLogo: require('~/assets/images/database.png'),
    jpLogo: require('~/assets/images/jp.png'),
    frontendLogo: require('~/assets/images/ux-design.png'),
    backendLogo: require('~/assets/images/db.png'),
    gameDesignLogo: require('~/assets/images/blueprint.png'),
    gameDevelopLogo: require('~/assets/images/game-development.png'),
};

export default images;
