import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faGithub } from '@fortawesome/free-brands-svg-icons';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames/bind';

import PageTitle from '~/components/PageTitle';
import Button from '~/components/Button';
import styles from './About.module.scss';

const cx = classNames.bind(styles);

function About({ about }) {
    const handleCopy = (e) => {
        navigator.clipboard.writeText(e.target.innerText);
        const notify = () => toast.success('Copied');
        notify();
    };

    return (
        <div className={cx('wrapper')}>
            <PageTitle title="About" />
            <div className={cx('container')}>
                <>
                    <img className={cx('img')} src={about.image} alt="Kha Trinh" />
                    <div className={cx('content')}>
                        <h4 className={cx('title')}>About Me</h4>
                        <div className={cx('introduce')}>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: about.description,
                                }}
                            />
                        </div>
                        <div className={cx('information')}>
                            <div className={cx('info-title')}>
                                {about.infomation.map((info, index) => (
                                    <div key={index}>{info.title}</div>
                                ))}
                            </div>
                            <div className={cx('info-content')}>
                                {about.infomation.map((info, index) => (
                                    <div key={index} onClick={(e) => handleCopy(e)}>
                                        {info.content}
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className={cx('button')}>
                            <Button
                                href="https://github.com/dawn1810"
                                primary={true}
                                leftIcon={<FontAwesomeIcon icon={faGithub} />}
                            >
                                Github
                            </Button>
                            <Button
                                href="https://www.facebook.com/profile.php?id=100028180361949"
                                primary={true}
                                leftIcon={<FontAwesomeIcon icon={faFacebook} />}
                            >
                                Facebook
                            </Button>
                            <Button
                                href={
                                    'https://drive.google.com/uc?export=download&id=1SuGh8rIEINAwFigvn21UHBqTobW3Z--p'
                                }
                                primary={true}
                                leftIcon={<FontAwesomeIcon icon={faDownload} />}
                            >
                                CV
                            </Button>
                        </div>
                    </div>
                </>
            </div>
        </div>
    );
}

About.propTypes = {
    about: PropTypes.object,
};

export default About;
