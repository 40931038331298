import { useRef } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faHouse, faPaperPlane, faPhone } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames/bind';

import { proxy } from '~/assets/api';
import Input from '~/components/Input';
import PageTitle from '~/components/PageTitle';
import Button from '~/components/Button';
import ContactInfo from '~/components/ContactInfo';
import styles from './Contact.module.scss';

const cx = classNames.bind(styles);

const icons = {
    faPhone: (
        <FontAwesomeIcon icon={faPhone} style={{ fontSize: '2.8rem', color: 'var(--primary)' }} />
    ),
    faEnvelope: (
        <FontAwesomeIcon
            icon={faEnvelope}
            style={{ fontSize: '2.8rem', color: 'var(--primary)' }}
        />
    ),
    faHouse: (
        <FontAwesomeIcon icon={faHouse} style={{ fontSize: '2.8rem', color: 'var(--primary)' }} />
    ),
};

const Contact = ({ contactInfo, className }) => {
    const nameRef = useRef(null);
    const emailRef = useRef(null);
    const subjectRef = useRef(null);
    const messageRef = useRef(null);

    const handleClick = async () => {
        const data = {
            name: nameRef.current.value,
            email: emailRef.current.value,
            subject: subjectRef.current.value,
            message: messageRef.current.value,
        };

        if (data.name && data.email && data.subject && data.message) {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'same-origin',
                body: JSON.stringify(data),
            };

            const response = await fetch(proxy + '/api/sendMessage', requestOptions);
            if (response.ok) {
                const notify = () => toast.success('Send mesage successful.');
                notify();
            } else {
                const notify = () => toast.error('Send mesage failed.');
                notify();
            }
        } else {
            const notify = () => toast.warning('Please fill all input to send message.');
            notify();
        }
    };

    return (
        <div className={cx('wrapper', className)}>
            <PageTitle title="Contact" />
            <div className={cx('content')}>
                <div className={cx('info')}>
                    {contactInfo.map((info, index) => (
                        <ContactInfo
                            key={index}
                            icon={icons[info.icon]}
                            mainInfo={info.main}
                            bonusInfo={info.bonus}
                        />
                    ))}
                </div>
                <div className={cx('input')}>
                    <Input
                        ref={nameRef}
                        className={cx('name-input')}
                        type="text"
                        placeholder="Your Name"
                    />
                    <Input
                        ref={emailRef}
                        className={cx('email-input')}
                        type="text"
                        placeholder="Your Email"
                    />
                    <Input
                        ref={subjectRef}
                        className={cx('subject-input')}
                        type="text"
                        placeholder="Subject"
                    />
                    <Input
                        ref={messageRef}
                        className={cx('message')}
                        placeholder="Message"
                        rows="10"
                        cols="50"
                    />
                    <Button
                        primary={true}
                        leftIcon={<FontAwesomeIcon icon={faPaperPlane} />}
                        large={true}
                        className={cx('submit')}
                        onClick={handleClick}
                    >
                        Send
                    </Button>
                </div>
            </div>
        </div>
    );
};

Contact.propTypes = {
    contactInfo: PropTypes.array,
    classNames: PropTypes.string,
};

export default Contact;
